<template>
  <div class="VQB-form">
    <builder
      :elements="localValue.elements"
      :selected-fields.sync="localValue.fields"
      :relations.sync="localValue.relations"
      :search-fields.sync="localValue.search"

      :load-children="loadNodeRelations"

      :initial-edit-mode="false"
      :show-selected-fields-area-on-non-edit-mode="true"
      :save-report-actions="false"
      :show-edit-mode-buttons="true"
      :show-build-report-buttons="false"
      :click-node-field-to-select="true"
      :select-fields-limit="selectFieldsLimit"
    ></builder>
  </div>
</template>

<script>

import Builder from "@/components/report/builder/Builder";

export default {
  name: 'VQBForm',
  components: {Builder},
  props: {
    value: { type: Object, required: true },
    selectFieldsLimit:{ type: Number, default: 0 },
  },
  computed: {},
  watch: {
    localValue: {
      deep: true,
      handler(val){
        this.$emit('input', this.localValue);
      },
    }
  },
  beforeMount() {
    if( !this.localValue.elements ){
      this.localValue = {
        elements: [],
        fields: [],
        relations: [],
        search: [],
      };
    }
    if( !this.localValue.elements.length ){
      this.loadRootBlocks();
    }
  },
  data() {
    return {
      localValue: this.value,
    }
  },

  methods: {
    loadNodeRelations(node, resolve) {
      this.getBlockChildren(node.key)
        .then(blocks => {
          resolve(blocks);
        })
    },

    loadRootBlocks() {
      this.getBlockChildren()
        .then(blocks => {
          this.localValue.elements = blocks;
        })
    },


    getBlockChildren(parent_key) {
      return this.$api.report.getBlocksByParent({
        parent_key: parent_key
      })
        .then(data => {
          return data.blocks;
        })
    },


  }
}

</script>


<style>
.VQB-form h2{
  font-size: 14px;
  margin: 8px 5px 10px 0 !important;
}
</style>
