<template>
  <div class="dashboard-widget-settings">

    <VQBForm
      v-if="localValue.seriesVqbs && localValue.seriesVqbs.length"
      v-model="localValue.seriesVqbs[0].vqb"
      :select-fields-limit="2"
    ></VQBForm>

  </div>
</template>

<script>
import Builder from "@/components/report/builder/Builder.vue";
import VQBForm from "@/components/report/builder/VQBForm.vue";

export default {
  name: 'VQBCellWidgetSettings',
  components: {VQBForm, Builder},
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    localValue: {
      get() {return this.value},
      set(value) {this.$emit('input', value)}
    }
  },
  data(){
    return {}
  },
  methods: {
  }
}
</script>